import A11yDialog from 'a11y-dialog';
import { wpAjaxRequest } from './wpAjax';
import { lenis } from './lenis';

export function initLoginForm(formElement) {
  const countryElement = formElement.querySelector('[name="username"]');
  const passwordElement = formElement.querySelector('[name="password"]');
  const rememberCheckbox = formElement.querySelector('[name="remember"]');
  const redirectLinkInput = formElement.querySelector('[name="redirect_link"]');
  const errorElement = formElement.querySelector('.login-form__error');
  const submitBtn = formElement.querySelector('button[type="submit"]');

  let country = countryElement.value;
  let password = passwordElement.value;

  const updateSubmit = () => {
    if (!country.length || !password.length) {
      submitBtn.disabled = true;
    } else {
      submitBtn.disabled = false;
    }

    errorElement.textContent = '';
    passwordElement.parentElement.classList.remove('form-group--has-error');
  };
  updateSubmit();

  countryElement.addEventListener('input', (e) => {
    country = countryElement.value;
    updateSubmit();
  });

  passwordElement.addEventListener('input', (e) => {
    password = passwordElement.value;
    updateSubmit();
  });

  formElement.addEventListener('submit', async (e) => {
    e.preventDefault();
    submitBtn.disabled = true;
    const redirectLink = redirectLinkInput.value;

    const response = await wpAjaxRequest('client_user_login', {
      user: country,
      pass: password,
      rem: rememberCheckbox.value,
      redirectLink: redirectLink.value,
    });

    if (response.status === 1) {
      window.location.href = redirectLink ? redirectLink : response.url;
    } else {
      errorElement.textContent = 'Invalid credentials';
      passwordElement.parentElement.classList.add('form-group--has-error');
      submitBtn.disabled = false;
    }
  });
}

export function initLogin() {
  const forms = document.querySelectorAll('.login-form');
  forms.forEach((form) => initLoginForm(form));

  const loginDialogElement = document.getElementById('login-dialog');
  if (loginDialogElement) {
    const dialog = new A11yDialog(loginDialogElement);

    dialog.on('show', () => {
      lenis.stop();
    });
    dialog.on('hide', () => {
      lenis.start();
    });

    const loginRequiredLinks = document.querySelectorAll(
      '[data-is-login-required]',
    );
    loginRequiredLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();

        if (link.href) {
          forms.forEach((form) => {
            const redirectInput = form.querySelector(
              'input[name="redirect_link"]',
            );
            if (redirectInput) redirectInput.value = link.href;
          });
        }

        dialog.show();
      });
    });
  }
}
