import { gsap } from 'gsap';
import { lenis } from './lenis';

const topbar = document.querySelector('.topbar');
const openBtn = document.querySelector('.topbar__menu');
const closeElements = [
  document.querySelector('.main-menu__overlay'),
  document.querySelector('.main-menu__close'),
];

const items = [...document.querySelectorAll('.main-menu__item')];
const loginButton = document.querySelector('.main-menu__login');

const tl = gsap.timeline({ paused: true });
let tt = 0;
items.forEach((item) => {
  tl.fromTo(
    item,
    { opacity: 0 },
    { opacity: 1, duration: 0.7, ease: 'power2.in' },
    `${tt}`,
  );
  tl.fromTo(
    item,
    { yPercent: 50 },
    { yPercent: 0, duration: 0.4, ease: 'power4.out' },
    '<+=0.2',
  );
  tt += 0.1;
});

tl.fromTo(loginButton, { opacity: 0 }, { opacity: 1 }, '>-=0.2');
tl.addLabel('socials', '>-=0.2');
const socials = [...document.querySelectorAll('.main-menu__social')].reverse();
let t = 0;
socials.forEach((social) => {
  tl.fromTo(
    social,
    { opacity: 0 },
    { opacity: 1, duration: 0.6, ease: 'power2.in' },
    `socials+=${t}`,
  );
  tl.fromTo(
    social,
    { yPercent: 70 },
    { yPercent: 0, duration: 0.4, ease: 'power4.out' },
    '<+=0.2',
  );
  t += 0.1;
});

function init() {
  if (openBtn) openBtn.addEventListener('click', open);

  closeElements.forEach((el) => {
    if (el) el.addEventListener('click', close);
  });
}

function handleKeyDown(e) {
  if (e.key === 'Escape') close();
}

function open() {
  openBtn.setAttribute('aria-expanded', 'true');
  window.addEventListener('keydown', handleKeyDown);
  topbar.classList.add('topbar--is-menu-open');
  lenis.stop();
  tl.play(0);
}

function close() {
  openBtn.setAttribute('aria-expanded', 'false');
  window.removeEventListener('keydown', handleKeyDown);
  topbar.classList.remove('topbar--is-menu-open');
  lenis.start();
}

export const mainMenu = { init, open, close };
