import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { topBar } from '../lib/topBar';
import { SplitText } from '../lib/SplitText3';

export function contactPage() {
  const text = document.querySelector('.contact__hero-text');
  const split = new SplitText(text, { type: 'lines' });

  ScrollTrigger.create({
    trigger: 'main',
    start: 'top+=50 top',
    // markers: true,
    onToggle: (self) => {
      if (self.isActive) topBar.addBackground();
      else topBar.removeBackground();
    },
  });

  const tl = gsap.timeline();
  tl.fromTo(
    '.contact__hero-heading',
    {
      x: -10,
    },
    {
      x: 0,
      duration: 1.5,
      ease: 'power1.out',
    },
  );
  tl.fromTo(
    '.contact__hero-heading',
    {
      clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
    },
    {
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
      duration: 1,
      ease: 'power4.in',
    },
    '<',
  );
  tl.fromTo(
    '.contact__hero-video',
    { opacity: 0 },
    { opacity: 1, duration: 0.7, ease: 'power2.in' },
    '<',
  );
  tl.fromTo(
    '.topbar',
    {
      yPercent: -100,
    },
    {
      yPercent: 0,
      y: 0,
      duration: 1,
      ease: 'power2.out',
    },
    '<75%',
  );

  tl.addLabel('offer', '<');
  let tt = 0;
  split.lines.forEach((line) => {
    tl.fromTo(
      line,
      { opacity: 0 },
      { opacity: 1, duration: 0.7, ease: 'power2.in' },
      `offer+=${tt}`,
    );
    tl.fromTo(
      line,
      { yPercent: 50 },
      { yPercent: 0, duration: 0.4, ease: 'power4.out' },
      '<+=0.2',
    );
    tt += 0.1;
  });

  const headings = document.querySelectorAll('.contact__section-heading');
  headings.forEach((heading) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: heading,
          top: 'top top+=80%',
          // markers: true,
        },
      })
      .fromTo(
        heading,
        { opacity: 0 },
        { opacity: 1, duration: 0.7, ease: 'power2.in' },
      );
  });

  const cards = document.querySelectorAll('.contact-card');
  cards.forEach((card) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: card,
          top: 'top top+=80%',
          // markers: true,
        },
      })
      .fromTo(
        card,
        { opacity: 0, y: 10 },
        { opacity: 1, y: 0, duration: 0.7, ease: 'power2.in' },
      );
  });
}
