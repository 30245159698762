import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mainMenu } from './lib/mainMenu';
import { contactPage } from './pages/contactPage';
import { landingPage } from './pages/landingPage';
import { footer } from './lib/footer';
import { archivePage } from './pages/archivePage';
import { portfolioPage } from './pages/portfolioPage';
import { notFoundPage } from './pages/notFoundPage';
import { lenis } from './lib/lenis';
import { initLogin } from './lib/login';
gsap.registerPlugin(ScrollTrigger);

lenis.on('scroll', () => ScrollTrigger.update());

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}
requestAnimationFrame(raf);

const html = document.documentElement;
if (html.classList.contains('route-landing')) landingPage(lenis);
else if (html.classList.contains('route-contact')) contactPage();
else if (html.classList.contains('route-archive')) archivePage();
else if (html.classList.contains('route-portfolio')) portfolioPage();
else if (html.classList.contains('route-404')) notFoundPage();
else {
  // generic page
  gsap.fromTo(
    '.topbar',
    {
      yPercent: -100,
    },
    {
      yPercent: 0,
      y: 0,
      duration: 1,
      ease: 'power2.out',
    },
  );
}

mainMenu.init();
footer();
initLogin();

console.log('JS inited');
