import A11yDialog from 'a11y-dialog';
import { VidstackPlayer, VidstackPlayerLayout } from 'vidstack/global/player';
import { lenis } from '../lib/lenis';

export function initVideoInfoDialogs() {
  const elements = document.querySelectorAll('.video-dialog');
  elements.forEach(async (element) => {
    const dialog = new A11yDialog(element);
    const video = element.querySelector('video');
    let player;

    if (video && !player) {
      player = await VidstackPlayer.create({
        target: video,
        autoPlay: false,
        controls: true,
      });
    }

    dialog.on('show', () => {
      if (player) {
        player.paused = false;
      }
      lenis.stop();
    });
    dialog.on('hide', () => {
      lenis.start();
      if (player) {
        player.paused = true;
        player.currentTime = 0;
      }
    });
  });
}
