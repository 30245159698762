/**
 * Determines whether the element is in the area of the viewport or not.
 *
 * @param elm - DOM element to test
 * @param threshold - The distance to the edge of the viewport before
 *                    the element is no longer inside in the viewport area,
 *                    in pixels
 *
 * @returns An object with indications of where the element is compared to the viewport area
 *
 * @link https://github.com/Tokimon/jsfns/blob/main/packages/web/src/inView.ts
 *
 * @example
 *
 * ```js
 * // Element inside viewport
 * const { inside } = inView(myElement); // --> inside === true
 *
 * // Element outside viewport
 * const { inside, below } = inView(myElement); // --> inside === false; below === true
 *
 * // With a threshold
 * const { inside } = inView(myElement, 30); // --> inside === true
 * ```
 */
export function inView(elm, threshold = 0) {
  const rect = elm.getBoundingClientRect();
  const vpWidth = window.innerWidth;
  const vpHeight = window.innerHeight;

  const above = rect.bottom - threshold <= 0;
  const below = rect.top - vpHeight + threshold >= 0;
  const left = rect.right - threshold <= 0;
  const right = rect.left - vpWidth + threshold >= 0;
  const inside = !above && !below && !left && !right;

  return { above, below, left, right, inside };
}

export function isInsideViewport(elm, threshold = 0) {
  return inView(elm, threshold).inside;
}
