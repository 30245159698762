import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { topBar } from '../lib/topBar';

export function notFoundPage() {
  ScrollTrigger.create({
    trigger: 'main',
    start: 'top+=50 top',
    // markers: true,
    onToggle: (self) => {
      if (self.isActive) topBar.addBackground();
      else topBar.removeBackground();
    },
  });

  const tl = gsap.timeline();
  tl.fromTo(
    '.p404__code',
    {
      x: -10,
    },
    {
      x: 0,
      duration: 1.5,
      ease: 'power1.out',
    },
  );
  tl.fromTo(
    '.p404__code',
    {
      clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
    },
    {
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
      duration: 1,
      ease: 'power4.in',
    },
    '<',
  );
  tl.fromTo(
    '.topbar',
    {
      yPercent: -100,
    },
    {
      yPercent: 0,
      y: 0,
      duration: 1,
      ease: 'power2.out',
    },
    '<75%',
  );

  tl.fromTo(
    '.p404__heading',
    { opacity: 0 },
    { opacity: 1, duration: 0.7, ease: 'power2.in' },
    '<',
  );
  tl.fromTo(
    '.p404__heading',
    { yPercent: 50 },
    { yPercent: 0, duration: 0.4, ease: 'power4.out' },
    '<+=0.2',
  );
}
