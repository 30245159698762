const element = document.querySelector('.topbar');

function addBackground() {
  element.classList.add('topbar--has-background');
}

function removeBackground() {
  element.classList.remove('topbar--has-background');
}

export const topBar = { addBackground, removeBackground };
