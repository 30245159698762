import { gsap } from 'gsap';

export function footer() {
  const tl = gsap
    .timeline({
      scrollTrigger: {
        trigger: '.footer',
        start: 'top bottom-=200',
        // markers: true,
      },
    })
    .fromTo(
      '.footer__arrow-inner',
      {
        yPercent: -100,
      },
      { yPercent: 0, duration: 1.5, ease: 'power4.out' },
    )
    .fromTo(
      '.footer__heading',
      {
        x: -10,
      },
      {
        x: 0,
        duration: 1.5,
        ease: 'power1.out',
      },
      '>-=0.8',
    )
    .fromTo(
      '.footer__heading',
      {
        clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
      },
      {
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
        duration: 1,
        ease: 'power4.in',
      },
      '<',
    );

  tl.addLabel('socials', '>-=0.2');
  const socials = [...document.querySelectorAll('.footer__social')].reverse();
  let t = 0;
  socials.forEach((social) => {
    tl.fromTo(
      social,
      { opacity: 0 },
      { opacity: 1, duration: 0.6, ease: 'power2.in' },
      `socials+=${t}`,
    );
    tl.fromTo(
      social,
      { yPercent: 70 },
      { yPercent: 0, duration: 0.4, ease: 'power4.out' },
      '<+=0.2',
    );
    t += 0.1;
  });

  tl.fromTo(
    '.footer__bottom',
    {
      opacity: 0,
    },
    {
      opacity: 1,
    },
    '<',
  );
}
